<template>
    <validation-observer ref="observer" v-slot="{ handleSubmit }" slim>
        <form class="auth-form" @submit.prevent="handleSubmit(submit)">
            <div class="auth-form__header">
                <router-link v-if="backRoute" :to="{ name: backRoute }" replace>
                    <arrow-left-icon />
                </router-link>

                <slot name="header" />
            </div>

            <div class="auth-form__body">
                <slot v-bind="{ request }" />
            </div>

            <text-button
                :theme="loadingSubmit || loading ? 'secondary' : theme"
                full
                :disabled="disableSubmit || loadingSubmit || loading"
                :loading="loadingSubmit || loading"
            >
                <slot name="button" />
            </text-button>

            <slot name="others" />
        </form>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { THEME_COLORS } from '@/constants/themeColors';
    import authRequest from '@/mixins/authRequestMixin';
    import responseProcessingMixin from '@/mixins/responseProcessingMixin';
    import TextButton from '@/components/buttons/TextButton';
    import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';

    export default {
        name: 'AuthForm',
        components: {
            ArrowLeftIcon,
            TextButton,
            ValidationObserver,
        },
        props: {
            action: {
                type: String,
                required: true,
            },
            backRoute: String,
            callback: {
                type: Function,
                required: true,
            },
            loadingSubmit: Boolean,
            disableSubmit: Boolean,
            theme: {
                type: String,
                default: 'primary',
                validator: value => THEME_COLORS.includes(value),
            },
        },
        mixins: [authRequest, responseProcessingMixin],
        methods: {
            submit() {
                this.loading = true;

                this.request(this.action, this.callback)
                    .then(() => {
                        if (this.$refs.observer) this.$refs.observer.reset();
                    })
                    .catch(({ response }) => this.processErrorResponse(response, this.$refs.observer))
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
        data() {
            return {
                error: null,
                loading: false,
                recaptchaToken: null,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .auth-form,
    .auth-form__body {
        display: flex;
        flex-direction: column;
    }

    .auth-form {
        grid-gap: 2.4rem;
        width: 100%;
        margin-left: auto;
        color: #67708c;
        font-family: 'Gotham Book', sans-serif;
        font-size: 1.4rem;
        font-weight: 325;

        ::v-deep .button {
            grid-gap: 1.2rem;
            height: 5.6rem;
            font-size: 1.5rem;

            svg {
                width: 2.2rem;
                height: 2.2rem;
            }

            &_secondary {
                background-color: #191f2e;

                &:hover {
                    background-color: #263047;
                }
            }
        }
    }

    .auth-form__header {
        display: grid;
        gap: 0.8rem;

        ::v-deep {
            > a {
                width: 2.8rem;
                height: 2.8rem;

                svg {
                    fill: #52596f;
                }

                &:hover {
                    opacity: 0.75;
                }
            }

            h1 {
                color: #ffffff;
                font-family: Gotham, sans-serif;
                font-size: 3.4rem;
                font-weight: 300;
                line-height: 1.2;
            }

            p {
                color: #67708c;
                font-family: Gotham, sans-serif;
                font-size: 1.4rem;
                font-weight: 325;
                line-height: 1.4;

                strong {
                    color: #ffffff;
                    font-weight: 350;
                }
            }
        }
    }

    .auth-form__body::v-deep {
        .button,
        .input-group {
            &:not(:first-child) {
                margin-top: 1.6rem;
            }
        }

        .input-group {
            grid-gap: 1rem;

            &__error {
                margin: 0;
            }

            &__label {
                margin: 0;
                font-size: 1.5rem;
                font-weight: 350;
            }
        }

        .input_medium .input__input {
            height: 5.3rem;
            padding: 0 2rem;
            font-size: 1.5rem;
            background-color: #181e2e;
            color: #ffffff;

            &::placeholder {
                color: #52596f;
            }
        }

        .input_medium .input__input_with-icon {
            padding-right: 6rem;
        }

        .input_medium .input__suffix {
            width: 6rem;

            svg {
                width: 2rem;
            }
        }
    }

    @media screen and (min-width: 64em) {
        .auth-form {
            max-width: 48rem;
            padding: 4rem;
            border-radius: 1.2rem;
            background-color: #0e1527;
            backdrop-filter: blur(1rem);
        }
    }
</style>
